import type * as apiTypes from './topics.apiV2.types';
import { BASE_API_OPTIONS, fetch } from 'fetch';

export const fetchTopicsData: apiTypes.FetchTopicsData = async params => {
  return fetch({
    url: `/topics`,
    dataType: 'Topics',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
