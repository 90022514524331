import { RequirementTaxonomyHandlers } from './useRequirementTaxonomyHandlers.types';
import { RequirementTaxonomyData } from './useRequirementTaxonomyData.types';
import type { RequirementTaxonomyProps } from '../RequirementTaxonomy.types';

export const useRequirementTaxonomyHandlers = ({
  localState,
  localActions,
  props
}: {
  localState: RequirementTaxonomyData['localState'];
  localActions: RequirementTaxonomyData['localActions'];
  props: RequirementTaxonomyProps;
}): RequirementTaxonomyHandlers => {
  const handleOpenAddLegalTermsModal: RequirementTaxonomyHandlers['handleOpenAddLegalTermsModal'] =
    () => {
      localActions.setIsAddLegalTermOpen(true);
    };

  const handleCloseAddLegalTermsModal: RequirementTaxonomyHandlers['handleCloseAddLegalTermsModal'] =
    () => {
      localActions.setIsAddLegalTermOpen(false);
    };

  const handleDeleteLegalTerm: RequirementTaxonomyHandlers['handleDeleteLegalTerm'] = legalTerm => {
    props.onChangeTaxonomy(
      'legal_terms',
      props.taxonomy.legal_terms.filter(legalTermItem => legalTermItem !== legalTerm.value)
    );
  };

  const handleAddNewLegalTerm: RequirementTaxonomyHandlers['handleAddNewLegalTerm'] = () => {
    props.onChangeTaxonomy('legal_terms', [...props.taxonomy.legal_terms, localState.newLegalTerm]);
    handleCloseAddLegalTermsModal();
  };

  const handleOpenAddSectionFocusModal: RequirementTaxonomyHandlers['handleOpenAddSectionFocusModal'] =
    () => {
      localActions.setIsAddSectionFocusOpen(true);
    };

  const handleCloseAddSectionFocusModal: RequirementTaxonomyHandlers['handleCloseAddSectionFocusModal'] =
    () => {
      localActions.setIsAddSectionFocusOpen(false);
    };

  const handleDeleteSectionFocus: RequirementTaxonomyHandlers['handleDeleteSectionFocus'] =
    sectionFocus => {
      props.onChangeTaxonomy(
        'section_focus',
        props.taxonomy.section_focus?.filter(
          sectionFocusItem => sectionFocusItem !== sectionFocus.value
        ) ?? []
      );
    };

  const handleAddNewSectionFocus: RequirementTaxonomyHandlers['handleAddNewSectionFocus'] = () => {
    props.onChangeTaxonomy('section_focus', [
      ...(props.taxonomy.section_focus ?? []),
      localState.newSectionFocus
    ]);
    handleCloseAddLegalTermsModal();
  };

  const handleOpenAddAdditionalConceptModal: RequirementTaxonomyHandlers['handleOpenAddAdditionalConceptModal'] =
    () => {
      localActions.setIsAddAdditionalConceptOpen(true);
    };

  const handleCloseAddAdditionalConceptModal: RequirementTaxonomyHandlers['handleCloseAddAdditionalConceptModal'] =
    () => {
      localActions.setIsAddAdditionalConceptOpen(false);
    };

  const handleDeleteAdditionalConcept: RequirementTaxonomyHandlers['handleDeleteAdditionalConcept'] =
    additionalConcept => {
      props.onChangeTaxonomy(
        'additional_concepts',
        props.taxonomy.additional_concepts?.filter(
          additionalConceptItem => additionalConceptItem !== additionalConcept.value
        ) ?? []
      );
    };

  const handleAddNewAdditionalConcept: RequirementTaxonomyHandlers['handleAddNewAdditionalConcept'] =
    () => {
      props.onChangeTaxonomy('additional_concepts', [
        ...(props.taxonomy.additional_concepts ?? []),
        localState.newAdditionalConcept
      ]);
      handleCloseAddAdditionalConceptModal();
    };

  const handleOpenAddExclusionCriteriaModal: RequirementTaxonomyHandlers['handleOpenAddExclusionCriteriaModal'] =
    () => {
      localActions.setIsAddExclusionCriteriaOpen(true);
    };

  const handleCloseAddExclusionCriteriaModal: RequirementTaxonomyHandlers['handleCloseAddExclusionCriteriaModal'] =
    () => {
      localActions.setIsAddExclusionCriteriaOpen(false);
    };

  const handleDeleteExclusionCriteria: RequirementTaxonomyHandlers['handleDeleteExclusionCriteria'] =
    exclusionCriteria => {
      props.onChangeTaxonomy(
        'exclusion_criteria',
        props.taxonomy.exclusion_criteria?.filter(
          exclusionCriteriaItem => exclusionCriteriaItem !== exclusionCriteria.value
        ) ?? []
      );
    };

  const handleAddNewExclusionCriteria: RequirementTaxonomyHandlers['handleAddNewExclusionCriteria'] =
    () => {
      props.onChangeTaxonomy('exclusion_criteria', [
        ...(props.taxonomy.exclusion_criteria ?? []),
        localState.newExclusionCriteria
      ]);
      handleCloseAddAdditionalConceptModal();
    };

  return {
    handleOpenAddLegalTermsModal,
    handleCloseAddLegalTermsModal,
    handleDeleteLegalTerm,
    handleAddNewLegalTerm,
    handleDeleteSectionFocus,
    handleCloseAddSectionFocusModal,
    handleOpenAddSectionFocusModal,
    handleAddNewSectionFocus,
    handleOpenAddAdditionalConceptModal,
    handleCloseAddAdditionalConceptModal,
    handleDeleteAdditionalConcept,
    handleAddNewAdditionalConcept,
    handleAddNewExclusionCriteria,
    handleDeleteExclusionCriteria,
    handleCloseAddExclusionCriteriaModal,
    handleOpenAddExclusionCriteriaModal
  };
};
