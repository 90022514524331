import type { RequirementTaxonomyStringencyOptions } from './useRequirementTaxonomyStringencyOptions.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useRequirementTaxonomyStringencyOptions = (): RequirementTaxonomyStringencyOptions => {
  return useMemo(
    () => ({
      stringencyOptions: [
        {
          value: 'high',
          label: (
            <uiLib.Typography className="requirement-taxonomy-item__header__status__item active">
              <uiLib.IconSuccessRounded />
              High
            </uiLib.Typography>
          )
        },
        {
          value: 'low',
          label: (
            <uiLib.Typography className="requirement-taxonomy-item__header__status__item deactivated">
              <uiLib.IconWarningRounded />
              Low
            </uiLib.Typography>
          )
        }
      ]
    }),
    []
  );
};
