import type { RequirementTaxonomyUnitOptions } from './useRequirementTaxonomyUnitOptions.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useRequirementTaxonomyUnitOptions = (): RequirementTaxonomyUnitOptions => {
  return useMemo(
    () => ({
      unitOptions: [
        {
          value: 'year',
          label: (
            <uiLib.Typography className="requirement-taxonomy-item__header__status__item active">
              <uiLib.IconSuccessRounded />
              Year
            </uiLib.Typography>
          )
        },
        {
          value: 'day',
          label: (
            <uiLib.Typography className="requirement-taxonomy-item__header__status__item deactivated">
              <uiLib.IconWarningRounded />
              Day
            </uiLib.Typography>
          )
        }
      ]
    }),
    []
  );
};
