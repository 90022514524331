import type * as apiTypes from './metaConcepts.apiV2.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const fetchMetaConceptsData: apiTypes.FetchMetaConceptsData = async params => {
  return fetch({
    url: `/meta_concepts`,
    dataType: 'MetaConcept',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
