import lodash from 'lodash';
import SourcesHeader from './SourcesHeader';
import SourcesFooter from './SourcesFooter';
import SourcesTable from './SourcesTable/SourcesTable';
import ROUTES from 'admin/constants/routes';

import { getAgencies } from 'shared/features/agencyData/agencyData.actions';
import { formatSearchValues } from './Sources.helper';
import { AGENCY_ATTRIBUTES, DEFAULT_AGENCY_ATTRIBUTES } from './Sources.constants';
import { sortOrderChangesHelper } from 'admin/utils/sort/sort.helper';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'admin/utils/hooks';
import { useDispatch, useSelector } from 'react-redux';

import './Sources.styles.scss';

const Sources = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const agencies = useSelector(state => lodash.get(state.agencyData, ['items'], []));
  const agencies_quantity = useSelector(state => lodash.get(state.agencyData, ['count'], 0));
  const load = useSelector(state => lodash.get(state.agencyData, ['load'], false));

  const [limit, setLimit] = useState(lodash.get(location.state, 'limit', 7));
  const [page, setPage] = useState(lodash.get(location.state, 'page', 1));
  const [sort, setSort] = useState(lodash.get(location.state, 'sort', {}));
  const [search, setSearch] = useState(lodash.get(location.state, 'search', {}));
  const [searchModal, setSearchModal] = useState(false);
  const [activeInfo, setActiveInfo] = useState(
    lodash.get(location.state, 'activeInfo', DEFAULT_AGENCY_ATTRIBUTES)
  );

  const setSearchValues = (name, value) => {
    setSearch(prevState => ({
      ...prevState,
      [name]: value
    }));
    setPage(1);
  };

  const changeFilter = fieldName => {
    const sortedFields = sortOrderChangesHelper(fieldName, sort);
    setSort(sortedFields);
    setPage(1);
  };

  const requestAgencies = () => {
    dispatch(
      getAgencies({
        offset: (page - 1) * limit,
        limit,
        agencies_quantity,
        sort: JSON.stringify(sort),

        ...formatSearchValues(search)
      })
    );
  };

  // TODO Fix deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(requestAgencies, [page, limit, sort]);

  const clearSearch = () => setSearch({});

  const applyAgencyFilters = () => {
    requestAgencies();
    setSearchModal(false);
  };

  const toggleSearch = () => {
    if (search[AGENCY_ATTRIBUTES.multiField]) {
      applyAgencyFilters();
    } else {
      setSearchModal(!searchModal);
    }
  };
  const navigateAgencyDetails = (ag, index) => {
    const id = ag.id || agencies[index].id;
    history.push(`${ROUTES.agenciesAdminTool}/${id}`, { page, limit, sort, activeInfo, search });
  };

  return (
    <div className="agencies">
      <SourcesHeader
        agenciesCount={agencies_quantity}
        search={search}
        show={searchModal}
        requestAgencies={applyAgencyFilters}
        clearSearch={clearSearch}
        toggleSearch={toggleSearch}
        changeFilter={setSearchValues}
      />
      <SourcesTable
        load={load}
        agencies={agencies}
        activeInfo={activeInfo}
        changeFilter={changeFilter}
        navigateAgencyDetails={navigateAgencyDetails}
        sort={sort}
        setActiveInfo={setActiveInfo}
      />
      <SourcesFooter
        limit={limit}
        externalActivePage={lodash.get(location.state, 'page', page)}
        pageQuantity={Math.ceil(agencies_quantity / limit)}
        onChangePage={setPage}
        onChangeLimit={setLimit}
      />
    </div>
  );
};

export default Sources;
