import type { DocumentVersionsFromResponse } from 'shared/features/documentData/documentData.types';
import type { DocumentVersionsDocketHandlers } from '../../../hooks/useDocumentVersionsDocketHandlers.types';

import React from 'react';
import * as uiLib from '@compliance.ai/web-components';
import _ from 'lodash';
import cn from 'classnames';
import ROUTES from 'admin/constants/routes';
import { Link } from 'react-router-dom';

import './_document-versions-docket-list.scss';

const DocumentVersionsDocketList = ({
  docVersions,
  currentDocVersionID,
  changeCurrentDocVersion
}: {
  docVersions: DocumentVersionsFromResponse[] | null | undefined;
  currentDocVersionID: number;
  changeCurrentDocVersion: DocumentVersionsDocketHandlers['changeCurrentDocVersion'];
}) => (
  <div className="document-list_details_items_list">
    {docVersions?.map(item => {
      const itemId = _.get(item, 'id');
      const isActive = itemId === currentDocVersionID;
      const createdAt = _.get(item, 'created_at', '');
      const updatedAt = _.get(item, 'updated_at', '');
      const pubDate = _.get(item, 'publication_date', '');
      const versoinizeStatus = _.get(item, 'versionize_status', '');
      const fulltextHash = _.get(item, 'full_text_hash', '');
      const fullxmlHash = _.get(item, 'full_xml_hash', '');
      const pdfHash = _.get(item, 'pdf_hash', '');
      const masterDocId = _.get(item, 'master_doc_id', '');
      const docId = _.get(item, 'doc_id', '');
      const versionizedDocId = _.get(item, 'versionized_doc_id', '');
      const isMasterVersion = masterDocId === docId;
      const hasUnofficialPublicationDate = item.has_unofficial_publication_date ? 'True' : 'False';

      return (
        <div
          key={itemId}
          onClick={() => changeCurrentDocVersion(itemId)}
          className={cn('document-list_details_items_list_element', {
            'document-list_details_items_list_element_active': isActive,
            'document-list_details_items_list_element_disabled': !isActive,
            'document-list_details_items_list_element_master_version': isMasterVersion
          })}
        >
          {isMasterVersion && (
            <div className="document-version-docket-list-link">
              <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H6}>
                {'Master doc id:'}
              </uiLib.Typography>
              <Link
                to={`${ROUTES.documents}/${masterDocId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {masterDocId}
              </Link>
            </div>
          )}
          {versionizedDocId && (
            <uiLib.Typography
              type={uiLib.TYPOGRAPHY_TYPES.H5}
            >{`versionize doc id: ${versionizedDocId}`}</uiLib.Typography>
          )}
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Created at: ${createdAt}`}</uiLib.Typography>
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Updated at: ${updatedAt}`}</uiLib.Typography>
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Has Unofficial Publication Date: ${hasUnofficialPublicationDate}`}</uiLib.Typography>
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Publication Date: ${pubDate}`}</uiLib.Typography>
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Versionize Status: ${versoinizeStatus}`}</uiLib.Typography>

          <uiLib.Tooltip
            title={fulltextHash}
            classNames={{
              tooltip: 'document-list_details_items_list_element_hash-text'
            }}
          >
            <uiLib.Typography
              type={uiLib.TYPOGRAPHY_TYPES.BODY1}
            >{`Full Text Hash: ${fulltextHash}`}</uiLib.Typography>
          </uiLib.Tooltip>
          <uiLib.Tooltip
            title={fullxmlHash}
            classNames={{
              tooltip: 'document-list_details_items_list_element_hash-text'
            }}
          >
            <uiLib.Typography
              type={uiLib.TYPOGRAPHY_TYPES.BODY1}
            >{`Full Xml Hash: ${fullxmlHash}`}</uiLib.Typography>
          </uiLib.Tooltip>
          <uiLib.Tooltip
            title={pdfHash}
            classNames={{
              tooltip: 'document-list_details_items_list_element_hash-text'
            }}
          >
            <uiLib.Typography
              type={uiLib.TYPOGRAPHY_TYPES.BODY1}
            >{`Pdf Hash: ${pdfHash}`}</uiLib.Typography>
          </uiLib.Tooltip>
        </div>
      );
    })}
  </div>
);

export default DocumentVersionsDocketList;
