import type {
  FetchDocumentCategoryResponse,
  MetaCategoryFromResponse
} from 'shared/features/categories/metaCategories/metaCategories.api.types';
import type { FetchMetaConceptsResponseV2 } from 'shared/features/metaConcepts/metaConcepts.apiV2.types';
import type { MetaConceptFromResponseV2 } from 'shared/features/metaConcepts/metaConcepts.api.types';

import * as constants from './useDocumentFiltersAsyncLoaders.constants';

export const createDocTypeDropdownOptions = (response: FetchDocumentCategoryResponse) => [
  ...response?.items.map((metaCategory: MetaCategoryFromResponse) => ({
    label: metaCategory.name,
    options: metaCategory?.cai_categories?.map(category => ({
      label: category.name,
      value: category.id
    }))
  })),
  constants.UNMAPPED_ELEMENT
];

export const createConceptsDropdownOptions = (response: FetchMetaConceptsResponseV2) => [
  ...response?.items.map((metaConcept: MetaConceptFromResponseV2) => ({
    label: metaConcept.name,
    options: metaConcept?.concepts?.map(concept => ({
      label: concept.name,
      value: concept.id
    }))
  }))
];
