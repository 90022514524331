import type { FC } from 'react';
import type { RequirementTaxonomyProps } from './RequirementTaxonomy.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './RequirementTaxonomy.constants';
import classNames from 'classnames';
import {
  useRequirementTaxonomyData,
  useRequirementTaxonomyUnitOptions,
  useRequirementTaxonomyQuantifiableOptions,
  useRequirementTaxonomyHandlers
} from './hooks';

import { useRequirementTaxonomyStringencyOptions } from './hooks/useRequirementTaxonomyStringencyOptions';
import './_requirement-taxonomy.scss';

export const RequirementTaxonomy: FC<RequirementTaxonomyProps> = props => {
  const { unitOptions } = useRequirementTaxonomyUnitOptions();
  const { quantifiableOptions } = useRequirementTaxonomyQuantifiableOptions();
  const { stringencyOptions } = useRequirementTaxonomyStringencyOptions();
  const { localState, localActions, formattedData } = useRequirementTaxonomyData({
    props,
    unitOptions: unitOptions,
    quantifiableOptions: quantifiableOptions,
    stringencyOptions: stringencyOptions
  });
  const handlers = useRequirementTaxonomyHandlers({ localState, localActions, props });

  return (
    <div className="requirement-taxonomy">
      <div className="requirement-taxonomy__header">
        <div className="requirement-taxonomy__header__tilte">
          <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H5}>Requirement Taxonomy</uiLib.Typography>
        </div>
      </div>
      <div className="requirement-taxonomy__body">
        <div className="requirement-taxonomy__body__text_info">
          <uiLib.TextField
            classNames={{
              labeledFieldContainer: 'requirement-taxonomy__body__text_info__field'
            }}
            label="Practical Examples"
            value={props.taxonomy.practical_examples}
            onChange={value =>
              props.onChangeTaxonomy(
                constants.REQUIREMENT_TAXONOMY_ATTRIBUTES.practicalExamples,
                value
              )
            }
            isError={!!props.errorFields.documentSearch}
            errorMessage={props.errorFields.documentSearch}
            isDisabled={!props.isEditMode}
          />
        </div>
        <div>
          {props.errorFields.taxonomy && (
            <uiLib.Typography
              status={uiLib.TYPOGRAPHY_STATUS.ERROR}
              type={uiLib.TYPOGRAPHY_TYPES.BODY1}
            >
              {props.errorFields.taxonomy}
            </uiLib.Typography>
          )}
        </div>
        <uiLib.List
          title="Legal Terms"
          options={formattedData.legalTerms}
          selectedOptions={[]}
          isOptionDeletable
          shouldRenderAddMoreButton
          withBorder
          addMoreButtonText="Add Legal Term"
          onOptionDelete={handlers.handleDeleteLegalTerm}
          onAddMoreClick={handlers.handleOpenAddLegalTermsModal}
        />
        <uiLib.List
          title="Section Focus"
          options={formattedData.sectionFocus}
          selectedOptions={[]}
          isOptionDeletable
          shouldRenderAddMoreButton
          withBorder
          addMoreButtonText="Add Section Focus"
          onOptionDelete={handlers.handleDeleteSectionFocus}
          onAddMoreClick={handlers.handleOpenAddSectionFocusModal}
        />
        <uiLib.List
          title="Additional Concepts"
          options={formattedData.additionalConcepts}
          selectedOptions={[]}
          isOptionDeletable
          shouldRenderAddMoreButton
          withBorder
          addMoreButtonText="Add Additional Concept"
          onOptionDelete={handlers.handleDeleteAdditionalConcept}
          onAddMoreClick={handlers.handleOpenAddAdditionalConceptModal}
        />
        <uiLib.List
          title="Exclusion Criteria"
          options={formattedData.exclusionCriteria}
          selectedOptions={[]}
          isOptionDeletable
          shouldRenderAddMoreButton
          withBorder
          addMoreButtonText="Add Exclusion Criteria"
          onOptionDelete={handlers.handleDeleteExclusionCriteria}
          onAddMoreClick={handlers.handleOpenAddExclusionCriteriaModal}
        />
        <div className="requirement-taxonomy__body__option">
          <uiLib.LabeledField label="Unit">
            <uiLib.Select
              className={classNames('requirement-taxonomy__header__status', {
                'requirement-taxonomy__header__status__active': props.taxonomy.unit === 'year',
                'requirement-taxonomy__header__status__deactivated': props.taxonomy.unit === 'day'
              })}
              type={uiLib.SELECT_TYPES.DEFAULT}
              value={formattedData.unit}
              options={unitOptions}
              isClearable={false}
              isSearchable={false}
              isDisabled={!props.isEditMode}
              onChange={props.onChangeUnit}
            />
          </uiLib.LabeledField>

          <uiLib.LabeledField label="Quantifiable">
            <uiLib.Select
              className={classNames('requirement-taxonomy__header__status', {
                'requirement-taxonomy__header__status__active': props.taxonomy.quantifiable,
                'requirement-taxonomy__header__status__deactivated': !props.taxonomy.quantifiable
              })}
              type={uiLib.SELECT_TYPES.DEFAULT}
              value={formattedData.quantifiable}
              options={quantifiableOptions}
              isClearable={false}
              isSearchable={false}
              isDisabled={!props.isEditMode}
              onChange={props.onChangeQuantifiable}
            />
          </uiLib.LabeledField>

          <uiLib.LabeledField label="Stringency">
            <uiLib.Select
              className={classNames('requirement-taxonomy__header__status', {
                'requirement-taxonomy__header__status__active':
                  props.taxonomy.stringency === 'high',
                'requirement-taxonomy__header__status__deactivated':
                  props.taxonomy.stringency === 'low'
              })}
              type={uiLib.SELECT_TYPES.DEFAULT}
              value={formattedData.stringency}
              options={stringencyOptions}
              isClearable={false}
              isSearchable={false}
              isDisabled={!props.isEditMode}
              onChange={props.onChangeStringency}
            />
          </uiLib.LabeledField>
        </div>
      </div>
      <uiLib.Modal
        isOpen={localState.isAddLegalTermOpen}
        title="Add new Legal Terms"
        withFooter
        primaryButtonText="Add Legal Term"
        secondaryButtonText="Cancel"
        onClose={handlers.handleCloseAddLegalTermsModal}
        onSubmit={handlers.handleAddNewLegalTerm}
      >
        <div>
          <uiLib.TextField
            value={localState.newLegalTerm}
            onChange={localActions.setNewLegalTerm}
          />
        </div>
      </uiLib.Modal>
      <uiLib.Modal
        isOpen={localState.isAddSectionFocusOpen}
        title="Add new Section Focus"
        withFooter
        primaryButtonText="Add Section Focus"
        secondaryButtonText="Cancel"
        onClose={handlers.handleCloseAddSectionFocusModal}
        onSubmit={handlers.handleAddNewSectionFocus}
      >
        <div>
          <uiLib.TextField
            value={localState.newSectionFocus}
            onChange={localActions.setNewSectionFocus}
          />
        </div>
      </uiLib.Modal>
      <uiLib.Modal
        isOpen={localState.isAddAdditionalConceptOpen}
        title="Add new Additional Concept"
        withFooter
        primaryButtonText="Add Additional Concept"
        secondaryButtonText="Cancel"
        onClose={handlers.handleCloseAddAdditionalConceptModal}
        onSubmit={handlers.handleAddNewAdditionalConcept}
      >
        <div>
          <uiLib.TextField
            value={localState.newAdditionalConcept}
            onChange={localActions.setNewAdditionalConcept}
          />
        </div>
      </uiLib.Modal>
      <uiLib.Modal
        isOpen={localState.isAddExclusionCriteriaOpen}
        title="Add new Exclusion Criteria"
        withFooter
        primaryButtonText="Add Exclusion Criteria"
        secondaryButtonText="Cancel"
        onClose={handlers.handleCloseAddExclusionCriteriaModal}
        onSubmit={handlers.handleAddNewExclusionCriteria}
      >
        <div>
          <uiLib.TextField
            value={localState.newExclusionCriteria}
            onChange={localActions.setNewExclusionCriteria}
          />
        </div>
      </uiLib.Modal>
    </div>
  );
};
