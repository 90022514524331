import type { RequirementTaxonomyQuantifiableOptions } from './useRequirementTaxonomyQuantifiableOptions.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useRequirementTaxonomyQuantifiableOptions =
  (): RequirementTaxonomyQuantifiableOptions => {
    return useMemo(
      () => ({
        quantifiableOptions: [
          {
            value: true,
            label: (
              <uiLib.Typography className="requirement-type-item__header__status__item active">
                <uiLib.IconSuccessRounded />
                True
              </uiLib.Typography>
            )
          },
          {
            value: false,
            label: (
              <uiLib.Typography className="requirement-type-item__header__status__item deactivated">
                <uiLib.IconWarningRounded />
                False
              </uiLib.Typography>
            )
          }
        ]
      }),
      []
    );
  };
