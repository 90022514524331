import type { ConceptDetailsHandlers } from './useConceptDetailsHandlers.types';

import { useEffect } from 'react';

export const useConceptDetailsLifecycle = ({
  onConceptChange,
  onRequirementTypeChange,
  onRequirementTaxonomyChange
}: {
  onConceptChange: ConceptDetailsHandlers['handleCalculateConcept'];
  onRequirementTypeChange: ConceptDetailsHandlers['handleRequirementTypeChange'];
  onRequirementTaxonomyChange: ConceptDetailsHandlers['handleRequirementTaxonomyChange'];
}) => {
  useEffect(onConceptChange, [onConceptChange]);
  useEffect(onRequirementTypeChange, [onRequirementTypeChange]);
  useEffect(onRequirementTaxonomyChange, [onRequirementTaxonomyChange]);
};
