// ----- TABLE CONSTANTS -----

import type { NormalizedRequirementTaxonomiesFromResponse } from 'shared/features/requirementTaxonomies/requirementTaxonomies.api.types';

export const REQUIREMENT_TAXONOMY_ATTRIBUTES = {
  id: 'id',
  conceptId: 'concept_id',
  practicalExamples: 'practical_examples',
  legalTerms: 'legal_terms',
  sectionFocus: 'section_focus',
  additionalConcepts: 'additional_concepts',
  exclusionCriteria: 'exclusion_criteria',
  stringency: 'stringency',
  unit: 'unit',
  quantifiable: 'quantifiable',
  isShared: 'is_shared',
  sharedResourceId: 'shared_resource_id'
};

export const NEW_REQUIREMENT_TAXONOMY_ID = 'new';

// Errors

export const ERRORS = {
  commonError: 'This value is required'
};

export const REQUIREMENT_TAXONOMY_DETAILS: NormalizedRequirementTaxonomiesFromResponse = {
  // general info
  id: 'new', // number | new
  concept_id: 0,
  practical_examples: '', // string array
  legal_terms: [], // string array
  section_focus: [],
  additional_concepts: [],
  exclusion_criteria: [],
  stringency: '',
  unit: '',
  quantifiable: true, // boolean
  is_shared: false, // boolean
  shared_resource_id: 0
};

export const REQUIREMENT_TAXONOMY_ACTIVE_TYPES = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated'
};

export const DELETE_CONFIRMATION_MODAL = {
  title: 'Delete requirement taxonomy',
  body: 'Are you sure you want to delete this requirement taxonomy?'
};
