import type { RequirementTaxonomyData } from './useRequirementTaxonomyData.types';
import type { RequirementTaxonomyProps } from '../RequirementTaxonomy.types';

import { RequirementTaxonomyUnitOptions } from './useRequirementTaxonomyUnitOptions.types';
import { RequirementTaxonomyQuantifiableOptions } from './useRequirementTaxonomyQuantifiableOptions.types';
import { RequirementTaxonomyStringencyOptions } from './useRequirementTaxonomyStringencyOptions.types';

import { useMemo, useState } from 'react';

export const useRequirementTaxonomyData = ({
  props,
  unitOptions,
  quantifiableOptions,
  stringencyOptions
}: {
  props: RequirementTaxonomyProps;
  unitOptions: RequirementTaxonomyUnitOptions['unitOptions'];
  quantifiableOptions: RequirementTaxonomyQuantifiableOptions['quantifiableOptions'];
  stringencyOptions: RequirementTaxonomyStringencyOptions['stringencyOptions'];
}): RequirementTaxonomyData => {
  const [isAddLegalTermOpen, setIsAddLegalTermOpen] =
    useState<RequirementTaxonomyData['localState']['isAddLegalTermOpen']>(false);
  const [newLegalTerm, setNewLegalTerm] =
    useState<RequirementTaxonomyData['localState']['newLegalTerm']>('');
  const [isAddSectionFocusOpen, setIsAddSectionFocusOpen] =
    useState<RequirementTaxonomyData['localState']['isAddSectionFocusOpen']>(false);
  const [newSectionFocus, setNewSectionFocus] =
    useState<RequirementTaxonomyData['localState']['newSectionFocus']>('');
  const [isAddAdditionalConceptOpen, setIsAddAdditionalConceptOpen] =
    useState<RequirementTaxonomyData['localState']['isAddAdditionalConceptOpen']>(false);
  const [newAdditionalConcept, setNewAdditionalConcept] =
    useState<RequirementTaxonomyData['localState']['newAdditionalConcept']>('');
  const [isAddExclusionCriteriaOpen, setIsAddExclusionCriteriaOpen] =
    useState<RequirementTaxonomyData['localState']['isAddExclusionCriteriaOpen']>(false);
  const [newExclusionCriteria, setNewExclusionCriteria] =
    useState<RequirementTaxonomyData['localState']['newExclusionCriteria']>('');

  const formattedData: RequirementTaxonomyData['formattedData'] = useMemo(
    () => ({
      practicalExamples: props.taxonomy.practical_examples,
      legalTerms: props.taxonomy.legal_terms?.map(item => ({ value: item, label: item })) ?? [],
      exclusionCriteria:
        props.taxonomy.exclusion_criteria?.map(item => ({ value: item, label: item })) ?? [],
      additionalConcepts:
        props.taxonomy.additional_concepts?.map(item => ({ value: item, label: item })) ?? [],
      unit: props.taxonomy.unit === 'year' ? unitOptions[0] : unitOptions[1],
      stringency:
        props.taxonomy.stringency === 'high' ? stringencyOptions[0] : stringencyOptions[1],
      quantifiable: props.taxonomy.quantifiable ? quantifiableOptions[0] : quantifiableOptions[1],
      sectionFocus: props.taxonomy.section_focus?.map(item => ({ value: item, label: item })) ?? []
    }),
    [
      unitOptions,
      quantifiableOptions,
      stringencyOptions,
      props.taxonomy.additional_concepts,
      props.taxonomy.exclusion_criteria,
      props.taxonomy.legal_terms,
      props.taxonomy.practical_examples,
      props.taxonomy.quantifiable,
      props.taxonomy.section_focus,
      props.taxonomy.stringency,
      props.taxonomy.unit
    ]
  );

  const localState: RequirementTaxonomyData['localState'] = {
    isAddLegalTermOpen,
    newLegalTerm,
    isAddSectionFocusOpen,
    newSectionFocus,
    isAddAdditionalConceptOpen,
    newAdditionalConcept,
    isAddExclusionCriteriaOpen,
    newExclusionCriteria
  };

  const localActions: RequirementTaxonomyData['localActions'] = useMemo(
    () => ({
      setIsAddLegalTermOpen,
      setNewLegalTerm,
      setIsAddSectionFocusOpen,
      setNewSectionFocus,
      setIsAddAdditionalConceptOpen,
      setNewAdditionalConcept,
      setIsAddExclusionCriteriaOpen,
      setNewExclusionCriteria
    }),
    []
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
