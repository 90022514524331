import type * as apiTypes from './requirementTaxonomies.api.types';

export const normalizeRequirementTaxonomiesResponse = (
  response: apiTypes.RequirementTaxonomiesResponse
): apiTypes.NormalizedRequirementTaxonomiesResponse => {
  return {
    count: response.count,
    requirementTaxonomies: response.items.map(normalizeRequirementTaxonomy)
  };
};

export const normalizeRequirementTaxonomy = (
  requirementTaxonomy: apiTypes.RequirementTaxonomiesFromResponse
): apiTypes.NormalizedRequirementTaxonomiesFromResponse => {
  return {
    id: requirementTaxonomy.id,
    concept_id: requirementTaxonomy.concept_id,
    practical_examples: requirementTaxonomy.practical_examples,
    legal_terms: requirementTaxonomy.legal_terms,
    section_focus: requirementTaxonomy.section_focus,
    additional_concepts: requirementTaxonomy.additional_concepts,
    exclusion_criteria: requirementTaxonomy.exclusion_criteria,
    stringency: requirementTaxonomy.stringency,
    unit: requirementTaxonomy.unit,
    quantifiable: requirementTaxonomy.quantifiable,
    shared_resource_id: requirementTaxonomy.shared_resource_id,
    is_shared: requirementTaxonomy.is_shared
  };
};
