import type { ConceptDetailsData } from './useConceptDetailsData.types';

import * as constants from '../ConceptDetails.constants';
import { ConceptDetailsProps } from '../ConceptDetails.types';

import { useMemo, useState } from 'react';
import { REQUIREMENT_TAXONOMY_DETAILS } from '../elements/RequirementTaxonomy/RequirementTaxonomy.constants';

export const useConceptDetailsData = ({
  props
}: {
  props: {
    id: ConceptDetailsProps['id'];
  };
}): ConceptDetailsData => {
  const [isEditMode, setIsEditMode] = useState<ConceptDetailsData['localState']['isEditMode']>(
    isNaN(Number(props.id))
  );
  const [errorFields, setErrorFields] = useState<ConceptDetailsData['localState']['errorFields']>(
    {}
  );
  const [details, setDetails] = useState<ConceptDetailsData['localState']['details']>(
    constants.CONCEPT_DETAILS_DEFAULT
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<ConceptDetailsData['localState']['isConfirmationModalOpen']>(false);
  const [isActive, setIsActive] = useState<ConceptDetailsData['localState']['isActive']>(false);
  const [documents, setDocuments] = useState<ConceptDetailsData['localState']['documents']>([]);
  const [jurisdiction, setJurisdiction] = useState<
    ConceptDetailsData['localState']['jurisdiction']
  >([]);
  const [docIdsSearch, setDocIdsSearch] =
    useState<ConceptDetailsData['localState']['docIdsSearch']>('');
  const [taxonomy, setTaxonomy] = useState<ConceptDetailsData['localState']['taxonomy']>(
    REQUIREMENT_TAXONOMY_DETAILS
  );

  const localState: ConceptDetailsData['localState'] = {
    isActive,
    documents,
    isEditMode,
    errorFields,
    details,
    jurisdiction,
    isConfirmationModalOpen,
    docIdsSearch,
    taxonomy
  };

  const localActions: ConceptDetailsData['localActions'] = useMemo(
    () => ({
      setIsActive,
      setDocuments,
      setIsEditMode,
      setErrorFields,
      setDetails,
      setJurisdiction,
      setIsConfirmationModalOpen,
      setDocIdsSearch,
      setTaxonomy
    }),
    []
  );

  const formattedData: ConceptDetailsData['formattedData'] = useMemo(() => {
    const metaConcept = localState.details.metaConcept;
    return {
      selectedMetaCategory: metaConcept ? { value: metaConcept.id, label: metaConcept.name } : null
    };
  }, [localState.details.metaConcept]);

  return {
    localState,
    formattedData,
    localActions
  };
};
