import type { ConceptDetailsData } from './useConceptDetailsData.types';
import type {
  AddRequirementTypesPayload,
  NormalizedRequirementTypeFromResponse
} from 'shared/features/requirementTypes/requirementTypes.api.types';

import * as constants from '../ConceptDetails.constants';
import * as documentConstants from 'admin/constants/docPipelineStatuses';
import * as uiLib from '@compliance.ai/web-components';
import type {
  AddRequirementTaxonomiesPayload,
  NormalizedRequirementTaxonomiesFromResponse
} from 'shared/features/requirementTaxonomies/requirementTaxonomies.api.types';

export const checkConceptChangeErrors = (
  concept: ConceptDetailsData['localState']['details']
): {
  isError: boolean;
  errorFields: ConceptDetailsData['localState']['errorFields'];
} => {
  let isError = false;
  const errorFields: ConceptDetailsData['localState']['errorFields'] = {};
  if (!concept.name) {
    isError = true;
    errorFields.name = constants.ERRORS.REQUIRED_FIELD;
  }
  if (!concept.advancedSearch) {
    isError = true;
    errorFields.advancedSearch = constants.ERRORS.REQUIRED_FIELD;
  }
  if (!concept.metaConcept) {
    isError = true;
    errorFields.metaConcept = constants.ERRORS.REQUIRED_FIELD;
  }
  return {
    isError,
    errorFields
  };
};

export const collectRequirementTypeInfoUpdate = ({
  requirementType,
  documents,
  isActive
}: {
  requirementType?: NormalizedRequirementTypeFromResponse[];
  documents: ConceptDetailsData['localState']['documents'];
  isActive: ConceptDetailsData['localState']['isActive'];
}): AddRequirementTypesPayload[0] => {
  // collect main info
  const newRequirementType: AddRequirementTypesPayload[0] = {};
  const docIds: number[] = documents.map(doc => doc.id);

  if (requirementType?.[0]?.isActive !== isActive) {
    newRequirementType.is_active = isActive;
  }

  const existDocIds: number[] =
    requirementType?.reduce((acc, reqType) => [...acc, ...reqType.docIds], [] as number[]) ?? [];
  if (existDocIds?.sort().toString() !== docIds.sort().toString()) {
    newRequirementType.doc_ids = docIds;
  }

  return newRequirementType;
};

export const collectRequirementTaxonomyInfoUpdate = ({
  requirementTaxonomy,
  newTaxonomy
}: {
  requirementTaxonomy?: NormalizedRequirementTaxonomiesFromResponse[];
  newTaxonomy: ConceptDetailsData['localState']['taxonomy'];
}): AddRequirementTaxonomiesPayload[0] => {
  // collect main info
  let newRequirementTaxonomy: AddRequirementTaxonomiesPayload[0] = {};

  if (requirementTaxonomy?.[0] !== newTaxonomy) {
    newRequirementTaxonomy = newTaxonomy;
  }

  return newRequirementTaxonomy;
};

export const checkRequirementTypeInfo = ({
  documents
}: {
  documents: ConceptDetailsData['localState']['documents'];
}): {
  errorFields: ConceptDetailsData['localState']['errorFields'];
  isError: boolean;
} => {
  const errorFields: ConceptDetailsData['localState']['errorFields'] = {};
  // check documents
  if (documents.some(doc => !uiLib.isNumber(doc.jurisdiction_id))) {
    errorFields.documents = constants.REQUIREMENT_TYPES_ERRORS.WRONG_DOCUMENT_JURISDICTION;
  } else if (
    documents.some(
      doc => doc.pipeline_status !== documentConstants.DOCUMENT_PIPELINE_STATUS.PROCESSED
    )
  ) {
    errorFields.documents = constants.REQUIREMENT_TYPES_ERRORS.WRONG_DOCUMENT_STATUS;
  }

  const isError = Object.keys(errorFields).length > 0;

  return {
    errorFields,
    isError
  };
};

export const sortDocuments = (
  params: uiLib.Params,
  documents: ConceptDetailsData['localState']['documents']
): ConceptDetailsData['localState']['documents'] => {
  const sortField = params?.[uiLib.TABLE_PARAMS.SORT];
  const docArray = structuredClone(documents);
  switch (sortField) {
    case constants.TABLE_FIELDS.ID:
      docArray.sort((doc1, doc2) => doc1.id - doc2.id);
      break;
    case `-${constants.TABLE_FIELDS.ID}`:
      docArray.sort((doc1, doc2) => doc2.id - doc1.id);
      break;
    case constants.TABLE_FIELDS.TITLE:
      docArray.sort((doc1, doc2) => (doc1.title > doc2.title ? 1 : -1));
      break;
    case `-${constants.TABLE_FIELDS.TITLE}`:
      docArray.sort((doc1, doc2) => (doc1.title < doc2.title ? 1 : -1));
      break;
    case constants.TABLE_FIELDS.JURISDICTION:
      docArray.sort((doc1, doc2) => (doc1.jurisdiction > doc2.jurisdiction ? 1 : -1));
      break;
    case `-${constants.TABLE_FIELDS.JURISDICTION}`:
      docArray.sort((doc1, doc2) => (doc1.jurisdiction < doc2.jurisdiction ? 1 : -1));
      break;
    case constants.TABLE_FIELDS.SENTENCES:
      docArray.sort((doc1, doc2) => (doc1.diffable ? 1 : -1));
      break;
    case `-${constants.TABLE_FIELDS.SENTENCES}`:
      docArray.sort((doc1, doc2) => (doc2.diffable ? 1 : -1));
      break;
    case constants.TABLE_FIELDS.PIPELINE_STATUS:
      docArray.sort((doc1, doc2) => (doc1.pipeline_status < doc2.pipeline_status ? 1 : -1));
      break;
    case `-${constants.TABLE_FIELDS.PIPELINE_STATUS}`:
      docArray.sort((doc1, doc2) => (doc1.pipeline_status > doc2.pipeline_status ? 1 : -1));
      break;
    default:
      break;
  }
  return docArray;
};
