export enum DOCUMENTS_DATA_FETCH_QUERY_PARAMS {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  USER = 'user',
  DOC_IDS = 'doc_ids',
  PIPELINE_STATUS = 'pipeline_status',
  PIPELINE_STATUSES = 'pipeline_statuses',
  REPORT_STATUSES = 'report_statuses',
  INTERACTION_STATUS = 'interaction_status',
  INTERACTION_STATUSES = 'interaction_statuses',
  JURISDICTION = 'jurisdiction',
  AGENCY_ID = 'agency_id',
  DOC_SCRAPPED_CATEGORY = 'doc_scraped_category',
  CATEGORY = 'category',
  SPIDER_NAME = 'spider_name',
  SPIDER_NAMES = 'spider_names',
  OFFICIAL_ID = 'official_id',
  DOCKET_ID = 'docket_id',
  OFFICIAL_IDS = 'official_ids',
  CITATION = 'citation',
  RESPONDENT_NAME = 'respondent_name',
  CITATION_ID = 'citation_id',
  CAI_CATEGORY_IDS = 'cai_category_ids',
  PUBLICATION_START_DATE = 'publication_date_start',
  PUBLICATION_END_DATE = 'publication_date_end',
  CREATED_AT_START_DATE = 'created_at_start',
  CREATED_AT_END_DATE = 'created_at_end',
  UPDATED_AT_START_DATE = 'updated_at_start',
  UPDATED_AT_END_DATE = 'updated_at_end',
  EFFECTIVE_ON_START_DATE = 'effective_on_start',
  EFFECTIVE_ON_END_DATE = 'effective_on_end',
  COMMENT_CLOSED_ON_START_DATE = 'comment_closed_on_start',
  COMMENT_CLOSED_ON_END_DATE = 'comment_closed_on_end',
  TITLE = 'title',
  PDF_URL = 'pdf_url',
  WEB_URL = 'web_url',
  RETURN_ONLY_IDS = 'return_only_ids',
  EITL_LABELS = 'eitl_labels',
  EXTEND_INFO = 'extend_info',
  HAS_UNOFFICIAL_PUBLICATION_DATE = 'has_unofficial_publication_date',
  VERSIONIZED = 'versionized',
  ONLY_LAST_VERSION = 'only_last_version',
  DIFFABLE = 'diffable',
  DOCUMENT_VERSIONS_COUNT = 'document_versions_count',
  DOCUMENT_VERSIONS_COUNT_FROM = 'document_versions_count_from',
  DOCUMENT_VERSIONS_COUNT_TO = 'document_versions_count_to',
  MONETARY_PENALTY = 'monetary_penalty',
  MONETARY_PENALTY_FROM = 'monetary_penalty_from',
  MONETARY_PENALTY_TO = 'monetary_penalty_to',
  VIOLATED_RULES = 'violated_rules',
  VIOLATION = 'violation',
  PARENT_DOCUMENT = 'parent_document',
  SHELF_ID = 'shelf_id',
  ROOT_SHELF_ID = 'root_shelf_id',
  IS_SHELF_EXIST = 'is_shelf_exist',
  CONCEPTS = 'concepts',
  TOPICS = 'topics',
  MASTER_DOCUMENT_ID = 'master_document_id',
  MASTER_DOCUMENT = 'master_document',

  /**
   * Used to only return specific parts of the response body
   */
  EXCLUSIVE_FIELDS = 'exclusive_fields',
  /**
   * When enabled, the request will return count
   */
  RETURN_DOCS_COUNT = 'return_docs_count',

  /**
   * When enabled, the request will return only the documents count without any items
   */
  SHOW_DOCS_COUNT = 'show_docs_count'
}

export const REDUCER_NAME = 'documentData';

export enum DocumentsBulkUpdateActions {
  ADD = 'add',
  EDIT = 'edit',
  REMOVE = 'remove',
  FLAG = 'flag',
  EXPORT = 'export'
}
