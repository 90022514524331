import { fetch, BASE_API_OPTIONS } from 'fetch';
import * as apiTypes from './requirementTaxonomies.api.types';

export const fetchRequirementTaxonomies: apiTypes.FetchRequirementTaxonomies = async params => {
  return fetch({
    url: `/requirement-taxonomy`,
    dataType: 'RequirementTaxonomy',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const addRequirementTaxonomies: apiTypes.AddRequirementTaxonomies = async params => {
  return fetch({
    url: `/requirement-taxonomy`,
    dataType: 'RequirementTaxonomy',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const updateRequirementTaxonomies: apiTypes.ModifyRequirementTaxonomies = async (
  id,
  params
) => {
  return fetch({
    url: `/requirement-taxonomy/${id}`,
    dataType: 'RequirementTaxonomy',
    method: 'PATCH',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const updateRequirementTaxonomiesByConcept: apiTypes.ModifyRequirementTaxonomiesByConcept =
  async (id, params) => {
    return fetch({
      url: `/requirement-taxonomy/concept/${id}`,
      dataType: 'RequirementTaxonomy',
      method: 'PATCH',
      baseApi: BASE_API_OPTIONS.BASE_API_DATA,
      params
    });
  };
